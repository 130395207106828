import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

interface GreenArrowIconProps {
    width: string
    height: string
}

export const GreenArrowIcon: FC<GreenArrowIconProps> = (props) => {

    const { width, height } = props;

  return (
    <Icon viewBox="0 0 176 78" w={width} h={height}>
        <svg width="176" height="78" viewBox="0 0 176 78" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="172" cy="4.19847" r="4" transform="rotate(180 172 4.19847)" fill="#02C6D6"/>
          <circle cx="144.152" cy="27.4656" r="4" transform="rotate(180 144.152 27.4656)" fill="#02C6D6"/>
          <circle cx="144.152" cy="4.19847" r="4" transform="rotate(180 144.152 4.19847)" fill="#02C6D6"/>
          <circle cx="116.306" cy="50.7328" r="4" transform="rotate(180 116.306 50.7328)" fill="#02C6D6"/>
          <circle cx="116.306" cy="27.4657" r="4" transform="rotate(180 116.306 27.4657)" fill="#02C6D6"/>
          <circle cx="116.306" cy="4.19848" r="4" transform="rotate(180 116.306 4.19848)" fill="#02C6D6"/>
          <circle cx="88.458" cy="74" r="4" transform="rotate(180 88.458 74)" fill="#02C6D6"/>
          <circle cx="88.458" cy="50.7328" r="4" transform="rotate(180 88.458 50.7328)" fill="#02C6D6"/>
          <circle cx="88.458" cy="27.4657" r="4" transform="rotate(180 88.458 27.4657)" fill="#02C6D6"/>
          <circle cx="88.458" cy="4.19848" r="4" transform="rotate(180 88.458 4.19848)" fill="#02C6D6"/>
          <circle cx="60.6104" cy="50.7328" r="4" transform="rotate(180 60.6104 50.7328)" fill="#02C6D6"/>
          <circle cx="60.6104" cy="27.4657" r="4" transform="rotate(180 60.6104 27.4657)" fill="#02C6D6"/>
          <circle cx="60.6104" cy="4.19848" r="4" transform="rotate(180 60.6104 4.19848)" fill="#02C6D6"/>
          <circle cx="32.7637" cy="27.4657" r="4" transform="rotate(180 32.7637 27.4657)" fill="#02C6D6"/>
          <circle cx="32.7637" cy="4.19849" r="4" transform="rotate(180 32.7637 4.19849)" fill="#02C6D6"/>
          <circle cx="4.91602" cy="4.19849" r="4" transform="rotate(180 4.91602 4.19849)" fill="#02C6D6"/>
        </svg>
    </Icon>
  );
};