import { Icon } from '@chakra-ui/react';
import { FC } from 'react';

interface CollectiverseIconProps {
    width: string
    height: string
}

export const CollectiverseIcon: FC<CollectiverseIconProps> = (props) => {

    const { width, height } = props;

  return (
    <Icon viewBox="0 0 810 675" w={width} h={height}>
        <svg width="810" height="675" viewBox="0 0 810 675" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M386.92 -42.8159L294.695 116.993C273.902 169.593 346.006 191.705 346.006 191.705C346.006 191.705 547.56 261.391 597.529 275.797C647.499 290.204 617.316 228.223 617.316 228.223C617.316 228.223 517.042 58.028 470.091 -24.7243C451.646 -56.8872 434.877 -66.6031 421.463 -66.6031C400.335 -66.6031 386.92 -42.8159 386.92 -42.8159Z" fill="url(#paint0_linear_2031_30120)"/>
          <path d="M386.751 -42.5908L308.505 93.5331C426.971 -6.55083 552.262 117.397 552.262 117.397L470.116 -24.5708C451.834 -56.7145 435.015 -66.6986 421.364 -66.6986C400.157 -66.6986 386.751 -42.5908 386.751 -42.5908Z" fill="url(#paint1_linear_2031_30120)"/>
          <path d="M198.871 298.699C198.871 298.699 43.1552 561.423 12.8771 613.708C-17.401 665.562 76.0286 669.019 118.418 669.019C210.55 671.179 247.749 613.708 281.487 565.744C315.226 518.212 367.131 511.298 393.084 511.298C459.695 516.051 531.498 567.04 562.641 595.128C646.554 671.179 714.031 669.019 714.031 669.019H805.298C868.45 669.883 841.199 603.77 841.199 603.77C841.199 603.77 803.135 542.842 781.508 499.631C709.706 356.17 621.467 339.75 621.467 339.75L312.63 239.068C305.71 237.339 298.789 236.907 292.301 236.907C228.284 236.907 198.871 298.699 198.871 298.699Z" fill="url(#paint2_linear_2031_30120)"/>
          <path d="M198.703 298.83C198.703 298.83 42.9747 561.4 12.7488 613.258C-17.477 665.772 75.8289 669.055 118.539 669.055C210.531 671.024 250.613 615.884 281.496 565.995C429.997 272.573 663.919 359.221 663.919 359.221C646.835 345.436 621.208 340.185 621.208 340.185L313.036 239.095C305.808 237.782 298.58 237.126 292.009 237.126C228.272 237.126 198.703 298.83 198.703 298.83Z" fill="url(#paint3_linear_2031_30120)"/>
          <defs>
          <linearGradient id="paint0_linear_2031_30120" x1="291.006" y1="105.706" x2="626.371" y2="105.706" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BE06BA"/>
          <stop offset="1" stopColor="#FF4F42"/>
          </linearGradient>
          <linearGradient id="paint1_linear_2031_30120" x1="308.505" y1="25.3488" x2="552.262" y2="25.3488" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02C6D6"/>
          <stop offset="0.99" stopColor="#FF257D"/>
          <stop offset="1" stopColor="#FF257D"/>
          </linearGradient>
          <linearGradient id="paint2_linear_2031_30120" x1="426.898" y1="236.907" x2="426.898" y2="669.019" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BE06BA"/>
          <stop offset="1" stopColor="#FF4F42"/>
          </linearGradient>
          <linearGradient id="paint3_linear_2031_30120" x1="6.83506" y1="452.95" x2="663.919" y2="452.95" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02C6D6"/>
          <stop offset="0.99" stopColor="#FF257D"/>
          <stop offset="1" stopColor="#FF257D"/>
          </linearGradient>
          </defs>
        </svg>
    </Icon>
  );
};