import {
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Text
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import {
  FaDiscord,
  FaEnvelope,
  FaFacebook,
  FaInstagramSquare,
  FaRedditAlien,
  FaTiktok,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';
// React Icon
import { FiMenu } from 'react-icons/fi';
import { IoCloseSharp } from 'react-icons/io5';
import { AnotoysCollectiverse } from 'src/components/Icons/Anotoys';

interface NavigationStyle {
  navBgStyle: string;
  navFillStyle: string;
  navBTN: string;
}

export const Navigation: FC<NavigationStyle> = (props) => {
  const { navBgStyle, navFillStyle, navBTN } = props;

  const [navigation, setNavigation] = useState(false);
  const [menuStyle, changeMenuStyle] = useState({
    menuDisplay: 'none',
    menuOpenIcon: 'flex',
    menuCloseIcon: 'none',
    menuHeaderbg: navBgStyle,
    menuLogo: navFillStyle,
  });

  const isMenuOpen = {
    menuDisplay: 'flex',
    menuOpenIcon: 'none',
    menuCloseIcon: 'flex',
    menuHeaderbg: 'white',
    menuLogo: 'black',
  };

  const isMenuClose = {
    menuDisplay: 'none',
    menuOpenIcon: 'flex',
    menuCloseIcon: 'none',
    menuHeaderbg: navBgStyle,
    menuLogo: navFillStyle,
  };

  const changeMenuColor = () => {
    if (window.scrollY >= 5) {
      setNavigation(true);
    } else {
      setNavigation(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeMenuColor);
  }, []);

  return (
    <>
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        py="1rem"
        px="1.5rem"
        position="fixed"
        zIndex="20"
        bgColor={navigation ? 'white' : 'blackAlpha.300' && menuStyle.menuHeaderbg}
      >
        <Flex alignItems="center">
          <Link href="/" w={['149px', '149px', '149px', '168px']}>
            <AnotoysCollectiverse
              width="100%"
              height="auto"
              fill={navigation ? 'black' : navFillStyle && menuStyle.menuLogo}
            />
          </Link>
          <Box bgColor="green.200" color="blackAlpha.900" borderRadius="10px" p="2px 10px" ml=".5rem">
            <Text fontSize="13px" fontWeight="bold">
              Coming Soon
            </Text>
          </Box>
        </Flex>

        <Flex display={['none', 'none', 'none', 'flex']} alignItems="center">
          <Link href="mailto:info@thecollectiverse.com">
            <Button
              variant={navigation ? 'darkBTN' : navBTN}
              >
              <Flex alignItems="center">
                <Text fontWeight="medium" mr=".5rem">
                  Email Us
                </Text>
                <FaEnvelope/>
              </Flex>
            </Button>
          </Link>
        </Flex>

        <IconButton
          aria-label="Open Menu"
          display={[menuStyle.menuOpenIcon, menuStyle.menuOpenIcon, menuStyle.menuOpenIcon, 'none']}
          bgColor="transparent"
          py="0"
          px="0"
          w="0"
          h="0"
          size="lg"
          icon={<FiMenu fontSize="24px" />}
          color={navigation ? 'black' : navFillStyle}
          onClick={() => changeMenuStyle(isMenuOpen)}
        />

        <IconButton
          aria-label="Close Menu"
          display={menuStyle.menuCloseIcon}
          bgColor="transparent"
          py="0"
          px="0"
          w="0"
          h="0"
          size="lg"
          icon={<IoCloseSharp fontSize="26px" />}
          color="black"
          onClick={() => changeMenuStyle(isMenuClose)}
        />
      </Flex>

      {/* mobile view */}
      <Flex
        display={menuStyle.menuDisplay}
        justifyContent="center"
        position="fixed"
        top="59.58px"
        left="0"
        w="100%"
        h="calc(100vh - 68.58px)"
        bgColor="white"
        zIndex="20"
      >
        <Flex w="475px" p="20px" flexDirection="column" overflowY="auto">
          <Link href="mailto:someone@example.com">
            <Button
                variant="fullWidthDarkBTN"
                mt="1.125rem"
              >
                <Flex alignItems="center">
                  <Text fontWeight="medium" mr=".5rem">
                    Email Us
                  </Text>
                  <FaEnvelope/>
                </Flex>
            </Button>
          </Link>
          <Flex mt='1.5rem' justifyContent={['center', 'center', 'center', 'right']}>
            <Link href="https://www.facebook.com/thecollectiverse" fontSize="1.25rem" mr=".75rem" isExternal>
              <FaFacebook />
            </Link>
            <Link href="https://youtube.com/Anotoys" fontSize="1.25rem" mr=".75rem" isExternal>
              <FaYoutube />
            </Link>
            <Link href="https://twitter.com/d_collectiverse" fontSize="1.25rem" mr=".75rem" isExternal>
              <FaTwitter />
            </Link>
            <Link href="https://discord.gg/thecollectiverse" fontSize="1.25rem" mr=".75rem" isExternal>
              <FaDiscord />
            </Link>
            <Link href="https://www.tiktok.com/@thecollectiverse" fontSize="1.125rem" mr=".75rem" isExternal>
              <FaTiktok />
            </Link>
            <Link href="https://www.instagram.com/thecollectiverse/" fontSize="1.25rem" mr=".75rem" isExternal>
              <FaInstagramSquare />
            </Link>
            <Link
              href="https://www.reddit.com/user/AnotoysPH?utm_source=share&utm_medium=ios_app&utm_name=iossmf"
              fontSize="1.25rem"
              isExternal
            >
              <FaRedditAlien />
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
