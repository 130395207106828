import { Box, Button, Flex, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, useDisclosure, Image } from "@chakra-ui/react";
import { FC } from "react";
import { FaLinkedin } from "react-icons/fa";

interface TeamCardInfo {
    name: string,
    position: string,
    shortBio: string,
    shortBioOne: string,
    shortBioTwo: string,
    shortBioThree: string,
    teamAvatar: string,
    linkedIn: string,
}

export const TeamCard : FC<TeamCardInfo> = (props) => {
    const {name, position, shortBio, teamAvatar, linkedIn, shortBioOne, shortBioTwo, shortBioThree} = props;
    let displayLinkedIn = 'block';

    const { isOpen, onOpen, onClose } = useDisclosure();

    if(linkedIn == ''){
        displayLinkedIn = 'none';
    }

    return (
        <>
        <Box w='100%' bgColor='white' borderRadius='1rem' boxShadow='0px 25px 50px -12px rgba(0, 0, 0, 0.25)' textAlign='center' p='2rem 1rem'>
            <Box w='100px' h='100px' display='inline-block'>
                <Image w='100%' h='auto' src={teamAvatar} objectFit="cover" alt="Avatar"/>
            </Box>
            <Box m='0 0 1.5rem'>
                <Text fontSize='1.25rem' fontWeight='medium' lineHeight='26px' color='black' mb='.25rem'>{name}</Text>
                <Text fontSize='1rem' lineHeight='22px' color='gray.900'>{position}</Text>
            </Box>
            <Box>
                <Button variant="darkBTN" onClick={onOpen}>View Profile</Button>
            </Box>
        </Box>

        <Modal size='lg' isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent mx="1.5rem">
                <ModalCloseButton />
                <ModalBody p='0'>
                    <Box p='2.25rem 1.5rem 1.5rem'>
                        <Text fontSize='1.5rem' fontWeight='bold' lineHeight='30px' color='black'>{name}</Text>
                        <Text color='gray.900' mb='1rem'>{position}</Text>
                        <Text color='gray.900' mb='1rem'>{shortBio}</Text>
                        <Text color='gray.900' mb='1rem'>{shortBioOne}</Text>
                        <Text color='gray.900' mb='1rem'>{shortBioTwo}</Text>
                        <Text color='gray.900' mb='1rem'>{shortBioThree}</Text>
                        <Flex alignItems='center' mb='1rem'>
                        <Link display={displayLinkedIn} href={linkedIn} isExternal >
                            <Box><FaLinkedin fontSize='1.25rem'/></Box>
                        </Link>
                        </Flex>
                        <Flex justifyContent='right'>
                            <Button color='gray.700'  onClick={onClose}>Close</Button>
                        </Flex>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
        </>
    );
};