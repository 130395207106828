import { Box, Flex, Link, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import {
  FaDiscord,
  FaFacebook,
  FaInstagramSquare,
  FaRedditAlien,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { AnotoysCollectiverse } from '../Icons/Anotoys';

export const Footer: FC = () => {
  return (
    <>
      <Box w="100%" bgColor="blackAlpha.100">
        <Box w="100%" pos='relative' textAlign='center' p='2.5rem 1.5rem 1.5rem'>

            <AnotoysCollectiverse width="160px" height="auto" fill="black"/>

            <Text fontSize=".875rem" lineHeight="18px" color="blackAlpha.600" mt={['1rem']}>
              Safe universe for fans to connect and collect official collectibles.
            </Text>

            <Flex justifyContent='center' m={['1.5rem 0 .5rem']}>
                <Link href="https://www.facebook.com/thecollectiverse" fontSize="1.5rem" mr="1rem" isExternal>
                  <FaFacebook />
                </Link>
                <Link href="https://youtube.com/Anotoys" fontSize="1.5rem" mr="1rem" isExternal>
                  <FaYoutube />
                </Link>
                <Link href="https://twitter.com/d_collectiverse" fontSize="1.5rem" mr="1rem" isExternal>
                  <FaTwitter />
                </Link>
                <Link href="https://discord.gg/thecollectiverse" fontSize="1.5rem" mr="1rem" isExternal>
                  <FaDiscord />
                </Link>
                <Link href="https://www.tiktok.com/@thecollectiverse" fontSize="1.375rem" mr="1rem" isExternal>
                  <FaTiktok />
                </Link>
                <Link href="https://www.instagram.com/thecollectiverse/" fontSize="1.5rem" mr="1rem" isExternal>
                  <FaInstagramSquare />
                </Link>
                <Link
                  href="https://www.reddit.com/user/AnotoysPH?utm_source=share&utm_medium=ios_app&utm_name=iossmf"
                  fontSize="1.5rem"
                  isExternal
                >
                  <FaRedditAlien />
                </Link>
            </Flex>           
              
            <Text color="blackAlpha.600">
              © 2022 Anotoys Collectiverse. Inc.
            </Text>
        </Box>
      </Box>
    </>
  );
};
